<template>
  <div>
    <custom-header></custom-header>
    <div class="container">
      <div class="show-bg" :style="'height:' + screenHeight + 'px'">
        <div class="bg-mask"></div>
        <div class="show-title">
          <p>每一个生命</p>
          <p>都值得被<span class="special-color">纪念</span></p>
          <p>安泰乐<span class="circle"></span>网上纪念馆</p>
        </div>
        <div class="show-click">
          <a href="https://apps.apple.com/cn/app/id1473078077">
            <img class="app-icon" src="@/assets/images/ios.png" alt="" />
            <p>APP Store</p>
          </a>
          <a href="https://www.yunzhongci.com/download/ATL.apk">
            <img class="app-icon" src="@/assets/images/Android.png" alt="" />
            <p>Android</p>
          </a>
        </div>
      </div>
      <div class="show-app" :style="'height:' + screenHeight + 'px'">
        <div class="show-word">
          <p class="first-word">APP端管理</p>
          <p class="last-word">随时随地 方便快捷</p>
        </div>
        <img class="app-img" src="@/assets/images/century.png" alt="" />
      </div>
      <div class="show-app show-more" :style="'height:' + screenHeight + 'px'">
        <!-- <div class="bg-mask"></div> -->
        <img class="app-img" src="@/assets/images/Spectrum.png" alt="" />
        <div class="show-word">
          <p class="app-word">APP更多功能 等你发现</p>
        </div>
      </div>
    </div>
    <custom-footer></custom-footer>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      screenHeight: 0, // 屏幕高度
    };
  },
  created() {
    this.screenHeight = document.documentElement.clientHeight;
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.container {
  width: 100%;
  margin: 0 auto;
  padding: 70px 0 0 0;
}

.show-bg {
  width: 100%;
  position: relative;
  background: url("~@/assets/images/appBg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8% 0 10%;
  box-sizing: border-box;
  .show-title {
    // font-weight: bold;
    z-index: 1;
    text-align: center;
    // padding: 15% 0 50px;
    font-size: 50px;
    color: #fff;
    box-sizing: border-box;
    letter-spacing: 10px;
    line-height: 75px;
    > p {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p:last-child {
      font-size: 16px;
      color: #cccccc;
      letter-spacing: 3px;
      // margin-top: 10px;
    }
    .circle {
      width: 5px;
      height: 5px;
      background-color: #ccc;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
    }
  }
  .show-click {
    display: flex;
    z-index: 1;
    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      width: 240px;
      height: 80px;
      border: 1px solid #fff;
      margin: 40px 10px;
      border-radius: 10px;
    }
  }
  .app-icon {
    width: 46px;
    height: 53px;
    margin-right: 17px;
  }
}
.bg-mask {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
}
.show-app {
  width: 100%;
  position: relative;
  background: url("~@/assets/images/actualbg.png") no-repeat;
  // background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 20% 0;
  box-sizing: border-box;
  .show-word {
    align-self: center;
    .first-word {
      color: #000;
      font-size: 40px;
      // padding-top: 7vw;
      margin-bottom: 10px;
    }
    .last-word {
      color: #999;
      font-size: 24px;
    }
  }
  .app-img {
    width: 40%;
  }
}
.show-more {
  background-image: url("~@/assets/images/Spectrumbg.png"),
    linear-gradient(#000, #000);
  background-blend-mode: lighten;
  align-items: center;
  .app-word {
    color: #fff;
    font-size: 30px;
    margin-top: 17vw;
  }
  .app-img {
    width: 30%;
  }
}

</style>

